<script setup lang="ts">
import type { Graph, GraphRun, Spell } from '@respell/database';
import { GraphType } from '@respell/database';
import { formatDistanceToNow } from 'date-fns';
import AppSpellIcon from '~/components/app/AppSpellIcon.vue';
import SpellActions from '~/components/spell/SpellActions.vue';
import { categories } from '~/util/categories';

const { spell, preview, templated } = defineProps<{
  spell: Spell & { versions?: Graph[] };
  preview?: GraphRun;
  templated?: boolean;
}>();

const spellStore = useSpellsStore();

const trigger = computed(
  () => spell?.versions?.find((v) => v.type === GraphType.live)?.trigger,
);
const isEnabled = computed(() => trigger.value?.isEnabled);

async function handleClick() {
  if (templated) {
    if (preview) {
      await navigateTo({
        name: 'preview',
        params: { spellId: spell.id },
      });
    } else {
      await spellStore.duplicateSpell(spell.id);
    }
  } else {
    await navigateTo({
      name: trigger.value ? 'spell.history' : 'spell.run',
      params: { spellId: spell.id },
    });
  }
}
</script>
<template>
  <div class="flex grow justify-start max-w-96">
    <UCard
      :ui="{
        shadow: 'hover:shadow-lg transition-all',
        divide: 'divide-y-0',
        header: {
          base: 'flex flex-row justify-between items-center',
          padding: 'pb-2',
        },
        body: {
          padding: '!py-0',
        },
        footer: {
          base: 'w-full flex flex-row h-14',
        },
      }"
      class="cursor-pointer w-[22rem]"
      @click="handleClick"
    >
      <template #header>
        <AppSpellIcon :spell="spell" />
        <img
          v-if="templated"
          src="../../public/respell-social-logo.png"
          style="width: 4.8rem"
        />
        <div v-else class="flex gap-2">
          <UButton
            v-if="trigger"
            icon="i-ph-circle-fill"
            size="2xs"
            :label="isEnabled ? 'Live' : 'Off'"
            color="white"
            variant="solid"
            :ui="{
              icon: {
                base: isEnabled ? 'text-green-500' : 'text-gray-300',
              },
            }"
          />
          <SpellActions :spell="spell" :is-triggered="!!trigger">
            <template #button>
              <UButton
                icon="i-ph-dots-three-outline-vertical-fill"
                size="lg"
                :padded="false"
                color="gray"
                variant="link"
              />
            </template>
          </SpellActions>
        </div>
      </template>

      <div class="flex flex-col w-full items-start">
        <p class="title line-clamp-1">{{ spell.name }}</p>
        <span v-if="templated" class="flex gap-1">
          <UIcon name="i-ph-circle-wavy-check-fill" class="text-primary-500" />
          <p class="caption">Official Template</p>
        </span>
        <p v-else-if="spell.updatedAt" class="caption">
          Edited {{ formatDistanceToNow(spell.updatedAt) }} ago
        </p>
        <span class="h-14 overflow-hidden">
          <MDC
            :value="spell?.description"
            tag="p"
            class="dimmed h-14 pt-1 line-clamp-2"
          />
        </span>
      </div>

      <template #footer>
        <div
          v-if="spell?.tags.length"
          class="overflow-x-scroll flex justify-start w-full gap-2"
        >
          <UButton
            v-for="category in spell?.tags"
            :key="category"
            :icon="categories[category].icon"
            :label="categories[category].name"
            size="2xs"
            color="gray"
            variant="solid"
          />
        </div>
        <!-- TODO: Build spell saving -->
        <!--        <UTooltip-->
        <!--          v-if="!template"-->
        <!--          text="Save this spell"-->
        <!--          class="ml-auto pl-s"-->
        <!--        >-->
        <!--          <UButton-->
        <!--            icon="i-ph-bookmark-simple"-->
        <!--            size="xl"-->
        <!--            :padded="false"-->
        <!--            color="gray"-->
        <!--            variant="link"-->
        <!--            disabled-->
        <!--            @click="() => spellStore.saveSpell(spell.id)"-->
        <!--          />-->
        <!--        </UTooltip>-->
      </template>
    </UCard>
  </div>
</template>
